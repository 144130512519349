import React from "react"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import RichText from "../components/rich-text"
import LinkBack from "../components/link-back"

const NewsPost = ({data, pageContext }) => {
  const post = data.newsPost;
  const { currentPage } = pageContext;
  return (
    <Layout>
      <Seo title={post.title}/>
      <section>
        <Container style={{maxWidth: '720px'}}>
          <Row>
            <Col>
              <p><LinkBack to={currentPage === 1 ? "/news" : `/news/${currentPage}`} label={'News'}/></p>
              <div className="news-post-preview-date">{post.publishedAt}</div>
              <h1>{post.title}</h1>
              {post.isNewsV1 ? <div dangerouslySetInnerHTML={{ __html: post.parent.html }} /> : <RichText blocks={post.parent._rawBody} />}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    newsPost(id: { eq: $id }) {
      title
      isNewsV1
      publishedAt(formatString: "DD MMMM YYYY", locale: "de")
      parent {
        ... on MarkdownRemark {
          html
        }
        ... on SanityPost {
          _rawBody(resolveReferences: {maxDepth: 20})
        }
      }
    }
  }
`

export default NewsPost
